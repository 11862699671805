import {Button, Card, Table} from 'flowbite-react';
import React from 'react';
import {useNavigate} from "react-router";

const Liquidation = () => {
    const navigate = useNavigate();
    return (
        <div className='md:w-full mx-auto'>
            <Card className="max-w">
                <div className="flex flex-col gap-4">
                    <div className='overflow-x-auto'>
                        <p className='mb-2'>INFORMATIONS SUR LE VEHICULE</p>
                        <hr/>

                        <Table>
                            <Table.Body>
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell>Immatriculation</Table.Cell>
                                    <Table.Cell>Date mise en circ.</Table.Cell>
                                    <Table.Cell>Marque</Table.Cell>
                                    <Table.Cell>Puiss. Moteur</Table.Cell>
                                    <Table.Cell>Chassis</Table.Cell>
                                    <Table.Cell>Nbr. Place</Table.Cell>
                                    <Table.Cell>Poids à charge</Table.Cell>
                                    <Table.Cell>Poids à vide</Table.Cell>
                                </Table.Row>
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 mt-8">
                                    <Table.Cell className='font-bold'>BSxxxxxxxxxx</Table.Cell>
                                    <Table.Cell className='font-bold'>xx/xx/xxx</Table.Cell>
                                    <Table.Cell className='font-bold'>xxxx</Table.Cell>
                                    <Table.Cell className='font-bold'>xxxx</Table.Cell>
                                    <Table.Cell className='font-bold'>xxxx</Table.Cell>
                                    <Table.Cell className='font-bold'>xxxxx</Table.Cell>
                                    <Table.Cell className='font-bold'>xxxx</Table.Cell>
                                    <Table.Cell className='font-bold'>xxxx</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </Card>
            <Card className="mt-5 max-w">
                <div className="flex flex-col gap-4 overflow-x-auto">
                    <div className='flex justify-between items-center'>
                        <p>LIQUIDATION DE LA TVM</p>
                        <div className='bg-amber-500 text-white font-medium p-1 rounded'>TOTAL DU : 30 000 FCFA</div>
                    </div>
                    <hr/>

                    <Table>
                        <Table.Head className="bg-white">
                            <Table.HeadCell>Année fis.</Table.HeadCell>
                            <Table.HeadCell>Montant</Table.HeadCell>
                            <Table.HeadCell>Echéance</Table.HeadCell>
                            <Table.HeadCell>Pénalité</Table.HeadCell>
                            <Table.HeadCell>Total dû</Table.HeadCell>
                        </Table.Head>
                        <hr/>
                        <Table.Body className='divide-y'>
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 mt-8">
                                <Table.Cell>2024</Table.Cell>
                                <Table.Cell>30 000</Table.Cell>
                                <Table.Cell>30-10-2024</Table.Cell>
                                <Table.Cell>0</Table.Cell>
                                <Table.Cell className='font-bold'>30 000</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>

                    <div className='flex flex-col w-full justify-between mt-2 mx-2'>
                        <Button className='bg-green-600 m-2 w-full' type="submit">Déclencher le processus de paiement</Button>
                        <Button className='bg-white m-2 text-black border border-gray-300 w-full'
                                onClick={() => navigate('/tvm')}>Retour</Button>
                    </div>

                </div>
            </Card>
        </div>
    );
};

export default Liquidation;