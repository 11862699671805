import React from 'react';
import services from '../../data/transport-logistique.json';
import {useNavigate} from 'react-router';
import {FaIdCard} from "react-icons/fa6";
import {NavLink} from "react-router-dom";

const TransportLogistique = () => {
    const navigate = useNavigate();
    return (
        <div className='mx-5'>
            <p className="text-2xl mb-5">Transport/Logistique</p>
            <div className="md:grid md:grid-cols-3 md:gap-4">
                {services.data.map((service) => (
                    <div className="bg-white p-8 rounded-lg shadow-sm md:w-72 border border-green-200 mb-2 md:mb-0">
                        <div className="flex items-center mb-4">
                            <div className="bg-green-100 rounded-full p-3">
                                <FaIdCard color='green'/>
                            </div>
                            <h2 className="ml-4 text-lg font-medium">{service.libelle}</h2>
                        </div>

                        <p className="text-gray-600 mb-4">
                            {service.description}
                            <p><NavLink to={`/detail/${service.libelle}`} state={service.libelle} className='underline text-green-800 font-medium'>Afficher les détails</NavLink></p>
                        </p>

                        <button
                            onClick={() => navigate(service.url)}
                            className="w-full bg-transparent mt-16 mb-0 hover:bg-green-500 text-green-500 font-semibold
                            hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded">
                            {service.id === 1 ? 'Payer TVM' : 'Faire une demande'}
                        </button>

                    </div>
                ))}
            </div>
        </div>
    );
};

export default TransportLogistique;