import React from 'react';
import TopBar from "../components/TopBar";
import {Carousel} from "flowbite-react";
import {Outlet} from "react-router";
import PiedPage from "../components/PiedPage";

const Layout2 = () => {
    return (
        <div>
            <TopBar/>
            <div className="h-64">
                <Carousel indicators={false} leftControl="">
                    <img src="https://www.alibabuy.com/photos/library/1500/10220.jpg" alt="..."/>
                    <img
                        src="/img/hotel-ledger-plaza-bangui.jpg"
                        alt="..."/>
                </Carousel>
            </div>
            <div className='md:m-10'>
                <div className='flex flex-row'>
                    <div className='container px-5 py-5'>
                        <Outlet/>
                    </div>
                </div>
            </div>

            <PiedPage/>
        </div>
    );
};

export default Layout2;