import React from 'react';
import {Outlet} from 'react-router';
import {Button, FloatingLabel, Tabs} from "flowbite-react";
import StepBar from "../../components/StepBar";
import {FaInfoCircle} from "react-icons/fa";

const TvmForm = () => {
    return (
        <>
            <Tabs aria-label="Pills" style="fullWidth">
                <Tabs.Item title="Payer ma TVM">
                    <div>
                        <div className='bg-green-100 ms:w-full h-1/8 p-10 border rounded-md'>
                            <p>FORMULAIRE DE PAIEMENT DE LA TAXE SUR LES VEHICULES A MOTEUR (TVM)</p>
                        </div>

                        <StepBar/>
                        <div className='my-10'>
                            <Outlet/>
                        </div>
                    </div>
                </Tabs.Item>
                <Tabs.Item title="Télécharger quittance TVM">
                    <div className='mx-5'>
                        <div className='flex bg-green-100 md:w-full h-1/8 p-5 border rounded-md my-2'>
                            <FaInfoCircle className='mt-1' color='teal'/>
                            <div className='items-center mx-2 space-y-1'>
                                <p className='font-medium'>Information</p>
                                <p>Renseignez la référence de la quittance de paiement de TVM.</p>
                            </div>
                        </div>

                        <FloatingLabel variant="filled" label="Référence de la quittance"/>
                        <Button className='bg-green-600 md:w-full'>Télécharger la quittance TVM</Button>
                    </div>

                </Tabs.Item>
            </Tabs>
        </>
    );
};

export default TvmForm;