import React from 'react';

const StepBar = () => {

    return (
        <div className="flex items-center max-w-screen-lg mx-auto mt-8 mb-4">
            <div className="flex items-center w-full">
                <div
                    className="w-8 h-8 shrink-0 mx-[-1px] bg-green-600 p-1.5 flex items-center justify-center rounded-full">
                    <span className="text-base text-white font-bold">1</span>
                </div>
                <span className='mx-2'>VÉHICULE</span>
                <div className="w-full h-1 bg-green-600"></div>
            </div>
            <div className="flex items-center w-full ml-2">
                <div
                    className="w-8 h-8 shrink-0 mx-[-1px] bg-green-600 p-1.5 flex items-center justify-center rounded-full">
                    <span className="text-base text-white font-bold">2</span>
                </div>
                <span className='mx-2'>IDENTIFICATION</span>
                <div className="w-full h-1 bg-green-600"></div>
            </div>
            <div className="flex items-center w-full ml-2">
                <div
                    className="w-8 h-8 shrink-0 mx-[-1px] bg-gray-300 p-1.5 flex items-center justify-center rounded-full">
                    <span className="text-base text-white font-bold">3</span>
                </div>
                <span className='mx-2'>PAIEMENT</span>
                <div className="w-full h-1 bg-gray-300"></div>
            </div>
            <div className="hidden md:flex items-center ml-2">
                <div
                    className="w-8 h-8 shrink-0 mx-[-1px] bg-gray-300 p-1.5 flex items-center justify-center rounded-full">
                    <span className="text-base text-white font-bold">4</span>
                </div>
                <span className='mx-2'>TERMINE</span>
            </div>
        </div>
    );
};

export default StepBar;