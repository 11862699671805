import React from 'react';
import {Button} from "flowbite-react";
import {FaIdCard} from "react-icons/fa6";
import {useNavigate} from "react-router";

const Home = () => {
    const homeServices = [
        {
            'titre': 'Délivrance du Casier judiciaire (Extrait B3 du casier judiciaire)',
            'description': 'Ce service permet à tout citoyen, tout étranger résidant ou ayant résidé en Centrafrique de demander un extrait B3 de son casier',
        },
        {
            'titre': 'Première immatriculation d’un véhicule 4 Roues et plus',
            'description': "Ce service permet d’immatriculer un véhicule neuf ou d'occasion de quatre (04) roues et plus. *Il est conseillé de prendre les services",
        },
        {
            'titre': 'Attestation fiscale',
            'description': 'Ce service permet de délivrer une attestation fiscale aux contribuables qui en font la demande. Il permet ainsi de  : **Pour  Le contribuable** *',
        },
        {
            'titre': 'TVM pour véhicule à 4 roues',
            'description': "Ce service permet de payer les taxes d'un véhicule neuf ou d'occasion de quatre (04) roues et plus. *Il est conseillé de prendre les services",
        },
    ]

    const navigate = useNavigate();
    return (
        <div className='flex flex-col justify-center items-center md:m-10'>
            <p className='text-3xl font-bold text-green-900 mb-10'>Les e-services populaires</p>

            <div className="md:grid md:grid-cols-2 md:gap-8 md:max-w-2xl space-y-5 md:space-y-0">
                {homeServices.map((s) => (
                    <div className="border md:border-0 w-96 bg-white p-8 rounded-lg relative md:mr-40">
                        <div className="absolute top-8 left-2 mr-5 bg-green-200 rounded-full p-5">
                            <FaIdCard width={200} color='green'/>
                        </div>

                        <div className="ml-12">
                            <h2 className="text-lg font-bold text-green-800">{s.titre}</h2>
                            <p className="mt-2 text-gray-600">{s.description}</p>
                        </div>
                    </div>
                ))}
            </div>

            <Button className='bg-amber-500 hover:bg-green-500' onClick={() => navigate('e-services')}>Afficher tous les
                services</Button>
        </div>
    );
};

export default Home;