import React from 'react';
import TopBar from "../components/TopBar";
import {Carousel} from "flowbite-react";
import BarreLateral from "../components/BarreLateral";
import {Outlet} from "react-router";
import PiedPage from "../components/PiedPage";
import {FaBuilding, FaGlobe, FaList} from "react-icons/fa";

const Layout = () => {
    const items = [
        {
            'name': 'E-SERVICES',
            'icon': <FaGlobe />,
            'active': true
        },
        {
            'name': 'SERVICES PAR ADMINISTRATION',
            'icon': <FaBuilding/>,
            'active': false
        },
        {
            'name': 'SERVICES PAR THEME',
            'icon': <FaList/>,
            'active': false
        },
    ]
    return (
        <div>
            <TopBar/>
            <div className='hidden h-20 bg-green-900 md:flex items-end justify-center md:space-x-32'>
                {items.map((item) => (
                    <div className='space-y-2.5'>
                        <div className={`${item.active ? 'text-white' : 'text-gray-400'} font-bold flex items-center space-x-2`}>
                            <div className='bg-green-600 rounded-full p-2'>{item.icon}</div>
                            <p>{item.name}</p>
                        </div>
                        {<div className={`h-1 ${item.active && 'bg-white'}`}></div>}
                    </div>
                ))}
            </div>
            <div className="h-64">
                <Carousel indicators={false} leftControl="">
                    <img src="https://www.alibabuy.com/photos/library/1500/10220.jpg" alt="..."/>
                    <img
                        src="/img/hotel-ledger-plaza-bangui.jpg"
                        alt="..."/>
                </Carousel>
            </div>
            <div className='md:m-10'>
                <div className='flex flex-row'>
                    <BarreLateral/>
                    <div className='container md:px-5 py-5'>
                        <Outlet/>
                    </div>
                </div>
            </div>

            <PiedPage/>
        </div>
    );
};

export default Layout;