import React from 'react';
import {Button, Navbar} from "flowbite-react";
import {NavLink} from "react-router-dom";

const TopBar = () => {
    return (
        <Navbar fluid className='bg-green-800 text-white'>
            <Navbar.Brand href="/">
                <img src="/img/centrafric.png" className="mr-10 md:h-12 h-9" alt="Logo Centrafrique"/>
                <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white"></span>
            </Navbar.Brand>

            <Navbar.Toggle className='text-white'/>
            <Navbar.Collapse>
                <NavLink className='text-white font-bold md:mt-4 ml-3' to="/e-services">
                    SERVICES
                </NavLink>
                <Navbar.Link className='text-white font-bold md:mt-4' href="#">
                    SERVICES PAR ADMINISTRATION
                </Navbar.Link>

                <Button className='bg-green-600 m-2'>Se connecter</Button>
                <Button className='bg-yellow-400 m-2 md:ml-0'>Creer un compte</Button>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default TopBar;