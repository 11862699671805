import React, {useState} from 'react';
import {Button, Label, Modal, TextInput} from "flowbite-react";
import {useNavigate} from "react-router";

const InfoVehicule = () => {
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();
    return (
        <div className='bg-green-50 w-full h-1/8 p-10 border rounded-md'>
            <h1 className='text-3xl text-green-800 font-bold'>Bienvenue sur le service de vérification de véhicule</h1>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400 py-2 mb-4">
                La plateforme Sécuroute facilite la gestion efficace du parc automobile.
                Muni de votre certificat d’immatriculation, accédez rapidement à vos informations et profitez de nos
                services en ligne pour une expérience sécurisée et simplifiée.
                Ce service vous permet de vérifier la conformité de vos véhicules, et de payer vos pénalités routières.
            </p>
            <Button className='bg-green-700' onClick={() => setOpenModal(true)}>Vérifier votre véhicule</Button>

            <Modal show={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header className='bg-green-800'><span className='text-white'>Vérifier votre véhicule</span></Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <form className="flex max-w-full flex-col">
                            <div className='my-1'>
                                <div className="mb-2 block">
                                    <Label htmlFor="matriculation" value="Numéro d'immatriculation"/>
                                </div>
                                <TextInput id="matriculation" type="text" placeholder="AA00000" required/>
                            </div>
                            <div className='my-1'>
                                <div className="mb-2 block">
                                    <Label htmlFor="chassis" value="5 derniers caractères du numéro de chassis"/>
                                </div>
                                <TextInput id="chassis" type="text" placeholder='00000' required/>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='w-full bg-green-700' onClick={() => navigate('/resultat-info-vehicule')}>Vérifier</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default InfoVehicule;