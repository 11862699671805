import React from 'react';
import {ApplicationRoutes} from "./routes/ApplicationRoutes";

function App() {
    return (
        <ApplicationRoutes />
    );
}

export default App;
