import {Button, Card, Label, Radio} from 'flowbite-react';
import React from 'react';
import {useNavigate} from "react-router";
import {FaInfoCircle} from "react-icons/fa";

const TypeVehicule = () => {
    const navigate = useNavigate();
    return (
        <div className='md:w-3/4 mx-2 md:mx-auto'>
            <p className='my-5 md:text-2xl font-bold text-green-600 text-center'>Quel type de véhicule souhaiteriez-vous payer ?</p>
            <Card className="max-w">
                <form className="flex flex-col gap-4">
                    <div className='bg-green-100 w-full h-1/8 p-5 border rounded-md flex items-center'>
                        <FaInfoCircle color='green'/>
                        <p className='ml-1'>Cochez le type de véhicule dont vous souhaiteriez payer la TVM.</p>
                    </div>
                    <div className='mt-2'>
                        <Radio id="vehicule-prive-public" name="type-vehicule" value="vehicule-prive-public"/>
                        <Label className='ml-1' htmlFor="vehicule-prive-public">VEHICULE PRIVE OU PUBLIC</Label>
                    </div>
                    <hr/>
                    <div className='mt-2'>
                        <Radio id="vehicule-articule" name="type-vehicule" value="vehicule-articule"/>
                        <Label className='ml-1' htmlFor="vehicule-articule">VEHICULE ARTICULE(TRACTEUR +
                            REMORQUE)</Label>
                    </div>
                    <hr/>
                    <div className='mt-2'>
                        <Radio id="vehicule-societe" name="type-vehicule" value="vehicule-societe"/>
                        <Label className='ml-1' htmlFor="vehicule-societe">VEHICULE DE SOCIETE</Label>
                    </div>
                    <Button className='bg-green-600 w-full' onClick={() => navigate('/identification-vehicule')}>Suivant</Button>
                </form>
            </Card>
        </div>
    );
};

export default TypeVehicule;