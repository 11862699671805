import React from 'react';
import eServces from '../data/eservices.json';
import {NavLink} from "react-router-dom";

const BarreLateral = () => {
    return (
        <div className="hidden md:flow-root md:ml-10 md:r-10 md:w-1/3">
            <ul className='divide-y divide-green-200 dark:divide-gray-700'>
                <li className="py-3 sm:py-4 text-green-800 font-bold">Tous les e-services</li>
                <li className="py-3 sm:py-4"></li>
                {eServces.data.map((service) => (
                    <li className="py-3 sm:py-4" key={service.id}>
                        <NavLink to={service.url} className='font-medium text-green-800'>{service.libelle}</NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default BarreLateral;