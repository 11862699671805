import React from 'react';
import {Button, ListGroup} from "flowbite-react";
import {HiClipboardDocumentList} from "react-icons/hi2";
import {FaIdCard} from "react-icons/fa6";
import {FaCheckCircle} from "react-icons/fa";
import {useLocation, useNavigate} from "react-router";

const DetailService = () => {
    let {state, pathname} = useLocation();
    const navigate = useNavigate();
    console.log(pathname);

    const data = [
        {
            'label': 'Durée estimée',
            'value': 'A définir',
        },
        {
            'label': 'Coût de service',
            'value': 'A définir'
        },
        {
            'label': 'Démarches pour l\'usager:\n',
            'value': 'A définir'
        },
        {
            'label': 'Qui peut faire la demande?',
            'value': 'A définir'
        },
        {
            'label': 'Institution en charge du service:',
            'value': 'A définir'
        },
        {
            'label': 'Institution compétente:',
            'value': 'A définir'
        },
        {
            'label': 'Législation',
            'value': 'A définir'
        },
        {
            'label': 'Localisation, heures de service:',
            'value': 'A définir'
        }
    ];
    return (
        <div className="flex flex-col md:flex-row">
            <div className="flex flex-col md:w-3/4 w-full">
                <button onClick={() => navigate('/transport-logistique')}
                        className="w-52 mb-4 px-4 py-2 bg-gray-400 text-white focus:outline-none">
                    Retour
                </button>

                <div className="flex items-center mb-4">
                    <div className="bg-green-100 rounded-full p-4">
                        <FaIdCard width={200} color='green'/>
                    </div>
                    <div className="ml-4">
                        <h2 className="text-green-800 font-medium text-3xl">{state}</h2>
                    </div>
                </div>
                <div>
                    Lorsque qu’un particulier paie un véhicule administratif de couleur de plaque vert, rouge ou bleu ;
                    il doit procéder lors de la mutation à la transformation de la couleur de la plaque. Les véhicules
                    particuliers pour usage personnel étant de couleur crème. Toutefois, l’intéressé peut transformer un
                    véhicule de couleur de plaque crème en couleur orange.
                </div>

                {data.map((d => (
                    <div className="flex items-center mt-4">
                        <FaCheckCircle color='green'/>
                        <div>
                            <div className="ml-4">
                                <h2 className="text-lg font-bold text-green-800">{d.label}</h2>
                            </div>
                            <p className="ml-4 mb-4">{d.value}</p>
                        </div>
                    </div>
                )))}

                <Button className='bg-amber-500 max-w-sm'>Faire une demande</Button>
            </div>

            <ListGroup className="md:w-1/4 h-64 mt-5 md:mt-0">
                <ListGroup.Item icon={HiClipboardDocumentList} className='bg-green-50'>
                    Liste des documents à fournir
                </ListGroup.Item>
                <div className="flex items-center mx-5 mt-5">
                    <ul className="ml-10 list-disc">
                        <li className='text-gray-700 py-1'>Carte grise du véhicule ;</li>
                        {pathname.includes('Visite') ?
                            <>
                                <li>Quittance du Trésor Public</li>
                                <li>Timbre fiscal</li>
                                <li>Reçu de paiement de la taxe sur véhicule à moteur (loi des finances)</li>
                            </> :
                            <>
                                <li className='text-gray-700 py-1'>Pièce d’identité du propriétaire ;</li>
                                <li className='text-gray-700 py-1'>Autorisation de "Institution Compétente" pour les
                                    plaques de
                                    couleur bleu ou
                                    rouge.
                                </li>
                            </>
                        }

                    </ul>
                </div>
            </ListGroup>
        </div>
    );
};

export default DetailService;