import {Button, Card, Label, Select, TextInput} from 'flowbite-react';
import React from 'react';
import {useNavigate} from "react-router";
import {FaInfoCircle} from "react-icons/fa";

const TypeVehicule = () => {
    const navigate = useNavigate();
    return (
        <div className='md:w-3/4 md:mx-auto mx-2'>
            <Card className="max-w">
                <form className="flex flex-col gap-4">
                    <div className='bg-green-100 w-full h-1/8 p-5 border rounded-md flex items-center'>
                        <FaInfoCircle color='green'/>
                        <p className='ml-1'>
                            Renseignez l'Immatriculation du véhicule et l'année fiscale que vous souhaiteriez payer.
                        </p>
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="email1" value="Renseignez votre immatriculation"/>
                        </div>
                        <div className='flex w-full justify-between'>
                            <TextInput className='mx-2' id="email1" type="text" placeholder="XX" required/>
                            <TextInput className='mx-2 w-2/3' id="email1" type="text" placeholder="XX" required/>
                            <TextInput className='mx-2' id="email1" type="text" placeholder="XX" required/>
                        </div>
                    </div>

                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="email1" value="Confirmer l'immatriculation *"/>
                        </div>
                        <div className='flex w-full justify-between'>
                            <TextInput className='mx-2' id="email1" type="text" placeholder="XX" required/>
                            <TextInput className='mx-2 w-2/3' id="email1" type="text" placeholder="XX" required/>
                            <TextInput className='mx-2' id="email1" type="text" placeholder="XX" required/>
                        </div>
                    </div>

                    <div>
                        <Select className='w-full m-2' id="countries" required>
                            <option>Année fiscale</option>
                            <option>2024</option>
                            <option>2023</option>
                            <option>2022</option>
                            <option>2021</option>
                            <option>2020</option>
                        </Select>
                    </div>

                    <div className='flex w-full justify-between mt-2 mx-2'>
                        <Button className='bg-white text-black border border-gray-300 w-1/2'
                                onClick={() => navigate('/tvm')}>Retour</Button>
                        <Button className='bg-green-600 mx-2 w-1/2' onClick={() => navigate('/liquidation')}>Suivant</Button>
                    </div>

                </form>
            </Card>
        </div>
    );
};

export default TypeVehicule;