import {Button, Table} from 'flowbite-react';
import React from 'react';

const Resultat = () => {
    return (
        <div className='overflow-x-auto m-5'>
            <div className='rounded py-3 px-2 bg-green-600 text-white overflow-x-auto'>Informations du véhicule</div>
            <Table className='rounded-none'>
                <Table.Head>
                    <Table.HeadCell>Immatriculation</Table.HeadCell>
                    <Table.HeadCell>N° de chassis</Table.HeadCell>
                    <Table.HeadCell>Marque et modele</Table.HeadCell>
                    <Table.HeadCell>Couleur de plaque</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell>12345678</Table.Cell>
                        <Table.Cell>12345678</Table.Cell>
                        <Table.Cell>Range Rover</Table.Cell>
                        <Table.Cell>CREME</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <Table className='rounded-none'>
                <Table.Head>
                    <Table.HeadCell>Statut TVM</Table.HeadCell>
                    <Table.HeadCell>Echéance Contrôle technique</Table.HeadCell>
                    <Table.HeadCell>Dernier Contrôle technique</Table.HeadCell>
                    <Table.HeadCell>Echéance Assurance automobile</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell>Dette 2024</Table.Cell>
                        <Table.Cell>01.10.2024</Table.Cell>
                        <Table.Cell>01.01.2024 Bangui</Table.Cell>
                        <Table.Cell>31.12.2024</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <h1 className='mt-5 mb-2'>Contraventions impayées</h1>
            <Table>
                <Table.Head>
                    <Table.HeadCell>N° de contravention</Table.HeadCell>
                    <Table.HeadCell>Infraction</Table.HeadCell>
                    <Table.HeadCell>Date</Table.HeadCell>
                    <Table.HeadCell>Montant dû</Table.HeadCell>
                    <Table.HeadCell></Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell>12345678</Table.Cell>
                        <Table.Cell>Excès de vitesse en agglomération : véhicules légers</Table.Cell>
                        <Table.Cell>28/12/2023</Table.Cell>
                        <Table.Cell>1000</Table.Cell>
                        <Table.Cell><Button className='max-w-sm bg-green-700'>Payer</Button></Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            <div className='flex border border-green-500 rounded mt-5 p-5 items-center'>
                <p className='text-green-600'>
                    Les informations affichées sur cette plateforme sont basées sur les données les plus récentes disponibles.
                    Cependant, il se peut qu'exceptionnellement certaines informations ne soient pas à jour en raison des délais
                    de mise à jour. Vous pouvez nous signaler toute information sur votre véhicule qui ne serait pas à jour en
                    nous envoyant un email.
                </p>

            </div>
        </div>
    );
};

export default Resultat;