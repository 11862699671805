import React from 'react';
import {FaIdCard} from "react-icons/fa6";
import {Button, Card, Label, ListGroup, Select, TextInput} from "flowbite-react";
import {HiInformationCircle} from "react-icons/hi";

const VisiteTechnique = () => {
    return (
        <div className="flex flex-col md:flex-row bg-green-50 p-5 md:mx-4 rounded">
            <div className="flex flex-col md:w-3/4 w-full">
                <div className="flex items-center mb-4">
                    <div className="bg-green-100 rounded-full p-4">
                        <FaIdCard width={200} color='green'/>
                    </div>
                    <div className="ml-4">
                        <h2 className="text-green-800 font-medium md:text-2xl">
                            Délivrance d'attestation du Contrôle Technique Automobile périodique
                        </h2>
                    </div>
                </div>

                <Card className="md:w-3/4 md:mr-5">
                    <form className="flex flex-col gap-2">
                        <Label className='mt-2' htmlFor="vehicule-prive-public">Véhicule immatriculé en Centrafrique ? </Label>
                        <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                            <input id="bordered-radio-1" type="radio" value="" name="bordered-radio"
                                   className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                            <label htmlFor="bordered-radio-1"
                                   className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">NON</label>
                        </div>
                        <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                            <input id="bordered-radio-1" type="radio" value="" name="bordered-radio"
                                   className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                            <label htmlFor="bordered-radio-1"
                                   className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">OUI</label>
                        </div>

                        <div className='mt-5'>
                            <Label htmlFor="vehicule-prive-public">Quel est le type du véhicule ? </Label>
                            <Select className='w-full' id="countries" required>
                                <option></option>
                            </Select>
                        </div>


                        <div className='w-full justify-between'>
                            <Label htmlFor="vehicule-prive-public">Entrez le numero d'immatriculation</Label>
                            <TextInput id="email1" type="text" required/>
                        </div>
                    </form>
                </Card>

                <Button className='bg-amber-500 sm:max-w-sm mt-2'>Déterminer la taxe</Button>
            </div>

            <ListGroup className="md:w-1/4 h-64 mt-5 md:mt-0">
                <ListGroup.Item icon={HiInformationCircle} className='bg-green-50'>
                    A propos de ce service
                </ListGroup.Item>
                <div className="items-center mx-5 mt-5">
                    <p>Vérification quotidienne de l'état technique des véhicules routiers en circulation sur
                    le réseau routier du pays</p>
                    <ul className="ml-10 list-disc">
                        <li>Quittance du Trésor Public</li>
                        <li>Timbre fiscal</li>
                        <li>Reçu de paiement de la taxe sur véhicule à moteur (loi des finances)</li>
                    </ul>
                </div>
            </ListGroup>
        </div>
    );
};

export default VisiteTechnique;