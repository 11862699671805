import React from 'react';
import {Footer} from "flowbite-react";
import {FaFacebookF, FaInstagram, FaLinkedinIn, FaPhone, FaRegEdit, FaTwitter} from "react-icons/fa";
import {FaCircleInfo} from "react-icons/fa6";
import {IoMail} from "react-icons/io5";


const PiedPage = () => {
    return (
        <Footer bgDark>
            <div className="w-full">
                <div className="grid grid-cols-2 gap-8 px-6 py-8 md:grid-cols-4 md:mx-32">
                    <div>
                        <Footer.Title className='text-white' title="Services publics" />
                        <Footer.LinkGroup col>
                            <Footer.Link href="#">
                                <div className='flex flex-row items-center text-gray-300'>
                                    <FaCircleInfo className='mx-2' /> <span>A propos</span>
                                </div>
                            </Footer.Link>

                            <Footer.Link href="#">
                                <div className='flex flex-row items-center text-gray-300'>
                                    <FaPhone className='mx-2' /> <span>Nous contacter</span>
                                </div>
                            </Footer.Link>
                        </Footer.LinkGroup>
                    </div>
                    <div>
                        <Footer.Title className='text-white' title="Contribuer" color='white' />
                        <Footer.LinkGroup col>
                            <Footer.Link href="#">
                                <div className='flex flex-row items-center text-gray-300'>
                                    <IoMail className='mx-2'/> <span>Faire une suggestion</span>
                                </div>
                            </Footer.Link>
                            <Footer.Link href="#">
                                <div className='flex flex-row items-center text-gray-300'>
                                    <FaRegEdit className='mx-2' /> Signaler un probleme
                                </div>
                            </Footer.Link>
                        </Footer.LinkGroup>
                    </div>
                    <div>
                        <Footer.Title className='text-white' title="En savoir plus" />
                        <Footer.LinkGroup col>
                            <Footer.Link href="#" className='text-gray-300'>Mentions légales</Footer.Link>
                            <Footer.Link href="#" className='text-gray-300'>CGU</Footer.Link>
                        </Footer.LinkGroup>
                    </div>
                    <div>
                        <Footer.Title className='text-white' title="Suivez-nous sur" />
                        <div className='mt-4 flex space-x-2 sm:mt-0 text-white'>
                            <FaTwitter />
                            <FaFacebookF />
                            <FaInstagram />
                            <FaLinkedinIn />
                        </div>
                    </div>
                </div>
                <div className="w-full bg-gray-700 px-4 py-6 flex items-center justify-center">
                    <Footer.Copyright className='text-white' href="#" by="Portail de l'administration centraficaine - service-public.cf" year={new Date().getFullYear()} />
                </div>
            </div>
        </Footer>
    );
};

export default PiedPage;