import {Route, Routes} from "react-router";
import Layout from "../features/Layout";
import Home from "../features/home/Home";
import InfoVehicule from "../features/infos-vehicule/InfoVehicule";
import Resultat from "../features/infos-vehicule/Resultat";
import {BrowserRouter} from "react-router-dom";
import TransportLogistique from "../features/transport-logistique/TransportLogistique";
import TvmForm from "../features/tvm/TvmForm";
import TypeVehicule from "../features/tvm/TypeVehicule";
import IdentificationVehicule from "../features/tvm/IdentificationVehicule";
import Eservices from "../features/eservices/Eservices";
import DetailService from "../features/eservices/DetailService";
import Layout2 from "../features/Layout2";
import Liquidation from "../features/tvm/Liquidation";
import VisiteTechnique from "../features/visite-technique/VisiteTechnique";

export function ApplicationRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout2 />}>
                    <Route path='/' element={<Home />}/>
                    <Route path='/detail/:titre' element={<DetailService />}/>
                    <Route path='/visite-technique' element={<VisiteTechnique />}/>
                </Route>

                <Route element={<Layout />}>
                    <Route path='/e-services' element={<Eservices />}/>
                    <Route path='/info-vehicule' element={<InfoVehicule />}/>
                    <Route path='/resultat-info-vehicule' element={<Resultat />}/>
                    <Route path='/transport-logistique' element={<TransportLogistique />}/>

                    <Route path='' element={<TvmForm />}>
                        <Route path='/tvm' element={<TypeVehicule />} />
                        <Route path='/identification-vehicule' element={<IdentificationVehicule />} />
                        <Route path='/liquidation' element={<Liquidation />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}